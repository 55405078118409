import React, { useState, useEffect } from "react";

import { Grid, Box, Typography, Container } from '@mui/material';
import { auth, firestore } from '../Firebase';
import { collection, onSnapshot, doc, orderBy, limit, query, getDocs, getDoc } from 'firebase/firestore';

import BookCard from "../components/BookCard";
import MemoCard from "../components/MemoCard";
import LikeBook from "../components/LikeBook";
import { useUserAuth } from "../AuthProvider";

const Home = () => {

  const [books, setBooks] = useState([]);
  const booksCollectionRef = collection(firestore, 'books');
  const [memos, setMemos] = useState([]);
  const memosCollectionRef = collection(firestore, 'memos');

  // useEffect(() => {
  //   const fetchBooks = async () => {
  //     const memosQuery = query(memosCollectionRef, orderBy('createTime', 'desc'));
  //     const memosSnapshot = await getDocs(memosQuery);

  //     let bookIds = [...new Set(memosSnapshot.docs.map(doc => doc.data().book))];
  //     bookIds = bookIds.slice(0, 9);

  //     const books = [];

  //     for (const bookId of bookIds) {
  //       const bookDoc = await getDoc(doc(firestore, 'books', bookId));
  //       books.push({ ...bookDoc.data(), id: bookId });
  //     }
      
  //     setBooks(books);
  //   };

  //   fetchBooks();
  // }, []);

  useEffect(() => {
    const fetchBooks = async () => {
        const recentBooksRef = doc(firestore, 'system', 'recentBooks');
        const recentBooksDoc = await getDoc(recentBooksRef);
        let bookIds = [];
        if (recentBooksDoc.exists()) {
            bookIds = recentBooksDoc.data().books;
        }

        const bookPromises = bookIds.map(bookId => getDoc(doc(firestore, 'books', bookId)));
        const bookDocs = await Promise.all(bookPromises);

        const books = bookDocs.map((bookDoc, index) => ({ ...bookDoc.data(), id: bookIds[index] }));
        
        setBooks(books);
    };

    fetchBooks();
  }, []);

  useEffect(() => {
    const memosQuery = query(memosCollectionRef, orderBy('createTime', 'desc'), limit(6));
    const unsubscribe = onSnapshot(memosQuery, (snapshot) => {
      setMemos(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    return () => unsubscribe();  // Cleanup function
  }, []); 

  return (
    <Box sx={{ width: '100%', }}>
      <Box pl={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', alignItems: 'center', zIndex: '100', }} bgcolor="#f8f8f8">
        <Typography variant="body1" component="div" sx={{ fontWeight: 'bold',}}>
          홈
        </Typography>
      </Box>
      <Box pb={2}>
        <Container>
        <Grid container spacing={1} className="book-section">
        <Grid mt={1} item xs={12}>
          <Typography 
          variant="h5" 
          component="div" 
          sx={{
            fontWeight: 'bold',
            color: '#000',
            marginBottom: '0.5rem',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
          >
            최신 메모
          </Typography>
        </Grid>
        {memos.map((memo) => (
          <Grid item xs={12} key={memo.id}>
            <MemoCard
              id={memo.id}
              title={memo.title}
              content={memo.content}
              bookId={memo.book}
            />
          </Grid>
        ))}
        </Grid>
        <Grid container spacing={1} className="book-section">
          <Grid mt={3} item xs={12}>
            <Typography 
            variant="h5" 
            component="div" 
            sx={{
              fontWeight: 'bold',
              color: '#000',
              marginBottom: '0.5rem',
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
            >
              최근에 추가된 책
            </Typography>
          </Grid>
        {books.map((book) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={book.id}>
            <BookCard
              id={book.id}
              title={book.title}
              image={book.image}
              author={book.author}
              publisher={book.publisher}
              description={book.description}
              isbn={book.isbn}
              pubdate={book.pubdate}
            >
          <div style={{ position: 'absolute', top: 5, right: 5 }}>
            <LikeBook id={book.id} likes={book.likes} />
          </div>
          </BookCard>
          </Grid>
        ))}
        </Grid>
        </Container>
      </Box>
    </Box>

  );
}

export default Home;