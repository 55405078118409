import React, { useState, useEffect } from 'react';
import { Tab, Grid, useMediaQuery, IconButton, Button, Box, Typography, Container, Card, CardMedia, CardContent, SvgIcon, CircularProgress } from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import blankAvatar from '../assets/img/blankAvatar.png';
import { useTheme } from '@mui/material/styles';
import { auth, firestore } from '../Firebase';
import { useUserAuth } from '../AuthProvider';
import { getDoc, collection, query, where, orderBy, getDocs, doc, onSnapshot } from 'firebase/firestore';
import LikeBook from '../components/LikeBook';
import BookCard from '../components/BookCard';
import MemoCard from '../components/MemoCard';
import EditIcon from '@mui/icons-material/Edit';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const UserProfile = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const [books, setBooks] = useState([]);
  const [nickname, setNickname] = useState('');
  const [introduce, setIntroduce] = useState('');
  const [loading, setLoading] = useState(true);
  const { user } = useUserAuth(auth);
  const { id } = useParams();  // Now id comes from the route parameter
  const navigate = useNavigate();

  const [memos, setMemos] = useState([]);

  useEffect(() => {
    // Now use the id from useParams to get the user data from Firestore
    const userRef = doc(firestore, 'users', id);

    const unsubscribe = onSnapshot(userRef, async (userSnapshot) => {
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const likedBooksIds = userData.likedBooks;

        if (likedBooksIds) {
          const likedBooks = await Promise.all(
            likedBooksIds.map(async (id) => {
              const bookSnapshot = await getDoc(doc(firestore, 'books', id));
              return { id: bookSnapshot.id, ...bookSnapshot.data() };
            })
          );

          setBooks(likedBooks);
        }

        const memosRef = collection(firestore, "memos");
        const memosQuery = query(memosRef, where("user", "==", id), orderBy("createTime"));
        const memosSnapshot = await getDocs(memosQuery);
  
        const memosData = await Promise.all(
          memosSnapshot.docs.map(async (memoDoc) => {
            const memoData = memoDoc.data();
            return { ...memoData, id: memoDoc.id };
          })
        );

        setNickname(userData.nickName || id);
        setIntroduce(userData.introduce || '');
        setLoading(false);
        setMemos(memosData.reverse());
      }
    });

    // Cleanup function
    return () => unsubscribe();

  }, [id]);  // Update the dependency array

  const reversedBooks = [...books].reverse();

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {  // 로딩 중일 때 CircularProgress 컴포넌트 보여주기
    return <CircularProgress />;
  }

  const handleEdit = () => {
    navigate(`/profile/edit`);
  };



  return (
    <Box sx={{ width: '100%', }}>

        {user && user.uid === id ? (
          <Box pl={2} pr={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: '100', }} bgcolor="#f8f8f8">
            <Box sx={{ display: 'flex', alignItems: 'center',}} >
              <Typography variant="body1" component="div">
                '{user.email}'님의 프로필
              </Typography>
            </Box>
            <IconButton sx={{ marginRight: 1,}} onClick={handleEdit}>
              <SvgIcon sx={{ width: 24, height: 24, }} component={EditIcon} />
            </IconButton>
          </Box>
          ):(
            <Box pl={2} pr={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: '100', }} bgcolor="#f8f8f8">
              <Box sx={{ display: 'flex', alignItems: 'center',}} >
                <Typography variant="body1" component="div">
                  '{nickname?.length > 18 ? `${nickname?.slice(0, 18)}...` : nickname}'님의 프로필
                </Typography>
              </Box>
            </Box>
        )}

      <Box pb={2}>
        <Container>
          <Grid container spacing={2} className="book-section">
            <Grid item xs={12}>
              <Card
                variant=""
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  // borderRadius: 2,
                  marginBottom: 4,
                }}
              >
                {/* <CardMedia
                  component="img"
                  src={ blankAvatar }
                  alt="image"
                  sx={{ margin: 4, marginLeft: isTablet ? 8 : 2, marginRight: isTablet ? 2 : 0, width: isTablet ? 160 : 110, height: isTablet ? 160 : 110, borderRadius: 100, border: '6px solid #eeeeee',}}
                /> */}
                <CardMedia
                  component="img"
                  src={ user?.photoURL || blankAvatar } // 사용자가 로그인한 경우 프로필 이미지, 아닌 경우 기본 이미지
                  alt="image"
                  sx={{ margin: 4, marginLeft: isTablet ? 8 : 2, marginRight: isTablet ? 2 : 0, width: isTablet ? 160 : 110, height: isTablet ? 160 : 110, borderRadius: 100, border: '6px solid #eeeeee',}}
                />
                <CardContent
                  sx={{ marginTop: 2, }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    className="book-card__title"
                    sx={{
                      fontSize: isTablet ? '1.8rem' : '1.4rem',
                      fontWeight: 'bold',
                      color: '#333',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '3',
                      WebkitBoxOrient: 'vertical',
                      marginBottom: isTablet ? '1rem': '.4rem',
                    }}
                  >
                    {nickname?.length > 18 ? `${nickname?.slice(0, 18)}...` : nickname}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontSize: '1.0rem',
                      color: '#666',
                      marginBottom: '1rem',
                    }}
                  >
                    {introduce}
                  </Typography>
                </CardContent>
              </Card>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 'bold',
                  color: '#333',
                  marginBottom: '1rem',
                }}
              >
                {`${nickname?.length > 18 ? `${nickname?.slice(0, 18)}...` : nickname}'s 서재`}
              </Typography>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList 
                    TabIndicatorProps={{ style: { background: "#1e3269" } }}
                    onChange={handleChange} 
                    aria-label="lab API tabs example"
                    textColor="#1e3269"
                  >
                    <Tab label="남긴 메모" value="1" />
                    <Tab label="저장한 책" value="2" />
                    {/* <Tab label="저장한 지식인" value="3" /> */}
                  </TabList>
                </Box>
                <TabPanel value="2" sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, width: '100%' }}>
                <Grid container spacing={1} className="book-section">
                  {reversedBooks.map((book) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={book.id}>
                      <BookCard
                        id={book.id}
                        title={book.title}
                        image={book.image}
                        author={book.author}
                        publisher={book.publisher}
                        description={book.description}
                        isbn={book.isbn}
                        pubdate={book.pubdate}
                      >
                        {user && (
                          <div style={{ position: 'absolute', top: 5, right: 5 }}>
                            <LikeBook id={book.isbn} likes={book.likes} />
                          </div>
                        )}
                      </BookCard>
                    </Grid>
                  ))}
                </Grid>
                </TabPanel>
                <TabPanel value="1" sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, width: '100%' }}>
                  <Grid container spacing={1} className="book-section">
                    {memos.map((memo) => (
                      <Grid item xs={12} key={memo.id}>
                        <MemoCard
                          id={memo.id}
                          title={memo.title}
                          content={memo.content}
                          bookId={memo.book}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
                {/* <TabPanel value="3">Item Three</TabPanel> */}
              </TabContext>


            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default UserProfile;