// import React, { Component, useState, useEffect } from 'react';
// import axios from 'axios';
// import { Box, TextField, InputAdornment, Container, Grid } from '@mui/material';

// import BookList from '../components/BookList';
// import BookCard from '../components/BookCard';
// import './Search.css';
// import SearchIcon from "@mui/icons-material/Search";

// const Search = () => {

//   const [isLoading, setIsLoading] = useState(true);
//   const [movies, setMovies] = useState([]);
//   const [value, setValue] = useState('');

//   // let timeoutId = null;
//   // const getSearchMovie = async () => {
//   //   const search = value;

//   //   // Wait for the user to stop typing for 500ms before making the request
//   //   clearTimeout(timeoutId);
//   //   timeoutId = setTimeout(async () => {
//   //     try {
//   //       if (search === "") {
//   //         setMovies([]);
//   //         setIsLoading(false);
//   //       } else {
//   //         const { data: { items } } = await axios.get('/api/search', {
//   //           params: {
//   //             query: search,
//   //           }
//   //         });
//   //         setMovies(items);
//   //         setIsLoading(false);
//   //       }
//   //     } catch (error) {
//   //       console.log(error);
//   //     }
//   //   }, 500);
//   // };

//   const getSearchMovie = async () => {
//     const ID_KEY = 'AjjPHNmAOeJJeAVj0i86';
//     const SECRET_KEY = '_SsVy5JLZb';
//     const search = value;

//     try {
//       if (search === '') {
//         setMovies([]);
//         setIsLoading(false);
//       } else {
//         const { data: { items } } = await axios.get('/api/v1/search/book.json', {
//           params: {
//             query: search,
//             display: 20,
//           },
//           headers: {
//             'X-Naver-Client-Id': ID_KEY,
//             'X-Naver-Client-Secret': SECRET_KEY,
//           },
//         });

//         setMovies(items);
//         setIsLoading(false);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getSearchMovie();
//   }, []);

//   const handleChange = (e) => {
//     setValue(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await getSearchMovie();
//   };

//   return (
//     <Box sx={{ width: '100%' }}>
//       {isLoading ? (
//         <div className="loader">
//           <span className="loader__text">Loading..</span>
//         </div>
//       ) : (
//         <form onSubmit={handleSubmit}>
//           <div>
//             <Box
//               sx={{
//                 position: 'sticky',
//                 top: 0,
//                 height: '60px',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//               }}
//               bgcolor="#f2f2f2"
//             >
//               <TextField
//                 type="text"
//                 value={value}
//                 onChange={handleChange}
//                 size="small"
//                 placeholder="어떤 책을 찾으세요?"
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <SearchIcon />
//                     </InputAdornment>
//                   ),
//                   sx: { borderRadius: 999, border: '3px solid #ddd' },
//                 }}
//                 sx={{
//                   '& fieldset': { border: 'none' },
//                   width: '90%',
//                   backgroundColor: '#fff',
//                   borderRadius: 10,
//                 }}
//               />
//             </Box>
//             {movies.map((book) => (
//               <BookList
//                 key={book.isbn}
//                 id={book.isbn}
//                 title={book.title}
//                 image={book.image}
//                 description={book.description}
//                 publisher={book.publisher}
//                 author={book.author}
//                 isbn={book.isbn}
//                 pubdate={book.pubdate}
//               />
//             ))}
//           </div>
//         </form>
//       )}
//     </Box>
//   );
// };

// export default Search;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Card, Grid, Box, TextField, InputAdornment, Skeleton } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";

import BookList from '../components/BookList';

const Search = () => {
  const [books, setBooks] = useState([]);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);  // input field reference

  let timeoutId = null;
  const getSearchBooks = async (query) => {
    setIsLoading(true);
    // Wait for the user to stop typing for 500ms before making the request
    clearTimeout(timeoutId);
    timeoutId = setTimeout(async () => {
      try {
        if (query === "") {
          setBooks([]);
          setIsLoading(false);
        } else {
          const { data: { items } } = await axios.get('/api/search', {
            params: {
              query: query,
            }
          });
          setBooks(items);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      window.history.pushState(null, '', `?q=${query}`);
    }, 500);
  };

  // const getSearchBooks = async (query) => {
  //   setIsLoading(true);
  //   const ID_KEY = 'AjjPHNmAOeJJeAVj0i86';
  //   const SECRET_KEY = '_SsVy5JLZb';

  //   if (query === '') {
  //     setBooks([]);
  //     setIsLoading(false);
  //   } else {
  //     const { data: { items } } = await axios.get('/api/v1/search/book.json', {
  //       params: {
  //         query: query,
  //         display: 20,
  //       },
  //       headers: {
  //         'X-Naver-Client-Id': ID_KEY,
  //         'X-Naver-Client-Secret': SECRET_KEY,
  //       },
  //     });
  //     setBooks(items);
  //     setIsLoading(false);
  //   }

  //   window.history.pushState(null, '', `?q=${query}`);
  // };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('q');
    if (query) {
      setValue(query);
      getSearchBooks(query);
    }
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getSearchBooks(value);
    if (inputRef.current) {
      inputRef.current.blur();  // remove focus from the input field
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '100',
            }}
            bgcolor="#f8f8f8"
          >
            <TextField
              type="text"
              value={value}
              onChange={handleChange}
              size="small"
              placeholder="어떤 책을 찾으세요?"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: 999, border: '3px solid #ddd' },
              }}
              sx={{
                '& fieldset': { border: 'none' },
                width: '95%',
                backgroundColor: '#fff',
                borderRadius: 10,
              }}
              ref={inputRef}  // attach the reference to the TextField
            />
          </Box>
          <Grid pl={2} pr={2} container spacing={1} className="book-section">
          {isLoading ? (
            <>
              <Grid item xs={12}>
                <Card
                  variant=''
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    height: '200',
                    borderRadius: 4,
                  }}
                >
                  <Skeleton variant="rectangular" width={'180px'} height={'180px'} sx={{ margin: 2, marginRight: 0, borderRadius: 5, }}/>
                  <Box sx={{ width: 600, marginTop: 2, marginBottom: 2,}}>
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  variant=''
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#f8f8f8',
                    height: '200',
                    borderRadius: 4,
                  }}
                >
                  <Skeleton variant="rectangular" width={'180px'} height={'180px'} sx={{ margin: 2, marginRight: 0, borderRadius: 5, }}/>
                  <Box sx={{ width: 600, marginTop: 2, marginBottom: 2,}}>
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card
                  variant=''
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#f8f8f8',
                    height: '200',
                    borderRadius: 4,
                  }}
                >
                  <Skeleton variant="rectangular" width={'180px'} height={'180px'} sx={{ margin: 2, marginRight: 0, borderRadius: 5, }}/>
                  <Box sx={{ width: 600, marginTop: 2, marginBottom: 2,}}>
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                    <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
                  </Box>
                </Card>
              </Grid> 
            </>

          ) : (
            books.map((book) => (
              <Grid item xs={12}>
                <BookList
                  key={book.isbn}
                  id={book.isbn}
                  title={book.title}
                  image={book.image}
                  description={book.description}
                  publisher={book.publisher}
                  author={book.author}
                  isbn={book.isbn}
                  pubdate={book.pubdate}
                />
              </Grid>
            ))
          )}
          </Grid>
      </form>
    </Box>
  );
};

export default Search;



