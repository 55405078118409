import React from "react";
import {   
  Card,
  CardMedia,
  CardContent,
  Typography } from '@mui/material';


function BookChoice({ id, title, image, author, publisher, onClick }) {
	return (
    <Card
      onClick={onClick}
      sx={{
        backgroundColor: '#fefefe',
        transition: 'background-color 0.3s',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#fff',
        },
      }}
    >
      <CardMedia
        component="img"
        src={image}
        alt="bookimage"
        sx={{ objectFit: 'contain', height: '60px', width: '60px', marginTop: 1, marginBottom: 1 }}
      />
      <CardContent
      >
        <Typography
          variant="h6"
          component="div"
          className="book-card__title"
          noWrap
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#333',
            marginBottom: '0.2rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {`${title?.length > 18 ? `${title?.slice(0, 18)}...` : title}`}
          
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          noWrap
          sx={{
            fontSize: '0.9rem',
            color: '#666',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {author} | {publisher}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default BookChoice;