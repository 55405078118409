import React, { useState } from 'react';
import { Box, Modal, Typography, TextField, Button } from '@mui/material';
import { useUserAuth } from "../AuthProvider";
import { firestore } from '../Firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import googleLogo from '../assets/img/googleLogo.png';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const LoginModal = () => {
  const { isModalOpen, closeModal, logIn, googleSignIn } = useUserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await logIn(email, password);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      // await googleSignIn();
      const { user } = await googleSignIn();

      // After signing in with Google, create a document in the users collection with the same ID as the user's uid
      const userRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userRef);
  
      // Only create a new document if it does not already exist
      if (!userDoc.exists()) {
        await setDoc(userRef, { likedBooks: [] });
      }

      // closeModal();


    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h4" mb={3} sx={{ fontWeight: 'bold', }}>
          Kogito 시작하기
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column',}}>
          <Typography variant="body1" component="h6" mb={4}>
            코기토는 지식인을 위한 독서 메모 블로그입니다.
          </Typography>
          <Box>
          <Typography variant="body2" component="div" mb={3} sx={{ fontWeight: 600, backgroundColor: '#f6bdcd', display: 'inline-block', padding: 1, borderRadius: 3,}}>
            책을 까먹지 않게 노트를 남기고 싶어요.
          </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="div" mb={3} sx={{ fontWeight: 600, backgroundColor: '#f8ca8f', display: 'inline-block', padding: 1, borderRadius: 3,}}>
              사진, 글꼴 편집없이 글만 빠르게 남기고 싶어요.
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="div" mb={3} sx={{ fontWeight: 600, backgroundColor: '#9cdef8', display: 'inline-block', padding: 1, borderRadius: 3,}}>
              지식인들의 생각이 궁금해요.
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="div" mb={4} sx={{ fontWeight: 600, backgroundColor: '#aee3d4', display: 'inline-block', padding: 1, borderRadius: 3,}}>
              내 생각을 사람들에게 알리고 싶어요.
            </Typography>
          </Box>
        </Box>
        {/* <TextField
          type="email"
          placeholder="이메일"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="password"
          placeholder="비밀번호"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={handleSubmit}>로그인</Button> */}
        {/* <button>
          <img src={googleLogo} alt="Logo"  style={{ width: 36, height: 36}} />
          <Typography>구글 계정으로 시작하기</Typography>
        </button> */}
        <Button onClick={handleGoogleSignIn} variant="outlined" sx={{ padding: 1, width: 210, height:50, borderRadius: 6,}}>
        <img src={googleLogo} alt="Logo" style={{ width: 20, height: 20,}} />
        <Typography ml={1} sx={{ fontWeight: 600, }}>구글 계정으로 시작하기</Typography>
        </Button>

      </Box>
    </Modal>
  );
};

export default LoginModal;