import React, { useState, useEffect } from 'react';
import { collection, addDoc, Timestamp, getDoc, doc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../Firebase';
import { useUserAuth } from '../AuthProvider';
import { useTheme } from '@mui/material/styles';
import LikedBooks from '../components/LikedBooks';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, useMediaQuery, Grid, Card, CardContent, TextField, Button, Typography, Box } from '@mui/material';

export default function AddMemo() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const { user } = useUserAuth(auth);
  const { id } = useParams();  // URL에서 메모 ID를 얻습니다.
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    createTime: Timestamp.now().toDate(),
    likes: [],
    user: user ? user.uid : '',
    book: '',
    isBookListVisible: false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBookSelect = () => {
    setFormData({ ...formData, isBookListVisible: true });
  };

  const handleBookChoice = (book) => {
    setFormData({ ...formData, book: book.id, bookTitle: book.title, isBookListVisible: false });
  };

  useEffect(() => {
    if (id) {
      // 메모 ID가 있으면 메모 수정 모드입니다. Firestore에서 해당 메모를 가져옵니다.
      const fetchMemo = async () => {
        const memoRef = doc(firestore, 'memos', id);
        const memoDoc = await getDoc(memoRef);
  
        if (memoDoc.exists()) {
          const memoData = memoDoc.data();
          const bookRef = doc(firestore, 'books', memoData.book);
          const bookDoc = await getDoc(bookRef);
  
          if (bookDoc.exists()) {
            const bookData = bookDoc.data();
            setFormData({ ...formData, ...memoData, bookTitle: bookData.title });
          } else {
            console.error('No such book!');
          }
        } else {
          console.error('No such document!');
        }
      };
  
      fetchMemo();
    }
  }, [id]);  // 메모 ID가 바뀔 때마다 이 훅을 실행합니다.

  // const handlePublish = async () => {
  //   if (!formData.title || !formData.content || !formData.book) {
  //     alert('Please fill all the fields');
  //     return;
  //   }

  //   try {
  //     const memoData = {
  //       title: formData.title,
  //       content: formData.content,  // 줄바꿈 문자를 그대로 저장
  //       createTime: Timestamp.now().toDate(),
  //       likes: [],
  //       user: user.uid,
  //       book: formData.book,
  //     };

  //     if (id) {
  //       // 메모 ID가 있으면 메모를 수정합니다.
  //       await setDoc(doc(firestore, 'memos', id), memoData);
  //     } else {
  //       // 메모 ID가 없으면 새 메모를 생성합니다.
  //       await addDoc(collection(firestore, 'memos'), memoData);
  //     }

  //     alert('Memo added successfully');
  //     setFormData({
  //       title: '',
  //       content: '',
  //       createTime: Timestamp.now().toDate(),
  //       likes: [],
  //       user: user ? user.uid : '',
  //       book: '',
  //       isBookListVisible: false,
  //     });
  //   } catch (err) {
  //     alert('Error adding memo');
  //   }
  // };

  const handlePublish = async () => {
    if (!formData.title || !formData.content || !formData.book) {
      alert('Please fill all the fields');
      return;
    }
  
    try {
      const memoData = {
        title: formData.title,
        content: formData.content,
        createTime: Timestamp.now().toDate(),
        likes: [],
        user: user.uid,
        book: formData.book,
      };
  
      if (id) {
        // 메모 ID가 있으면 메모를 수정합니다.
        await setDoc(doc(firestore, 'memos', id), memoData);
      } else {
        // 메모 ID가 없으면 새 메모를 생성합니다.
        await addDoc(collection(firestore, 'memos'), memoData);
      }

        // 새로운 메모의 책을 "최근에 메모가 추가된 책" 리스트에 추가합니다.
      const recentBooksRef = doc(firestore, 'system', 'recentBooks');
      const recentBooksDoc = await getDoc(recentBooksRef);
      let recentBooks = [];
      if (recentBooksDoc.exists()) {
        recentBooks = recentBooksDoc.data().books;
      }
      
      // 새 책이 이미 리스트에 존재하지 않을 경우에만 추가합니다.
      if (!recentBooks.includes(formData.book)) {
        recentBooks.unshift(formData.book);  // 새 책을 리스트의 앞에 추가합니다.
        if (recentBooks.length > 9) {
          recentBooks.pop();  // 리스트가 9개를 초과하면 가장 오래된 책을 제거합니다.
        }
        await setDoc(recentBooksRef, { books: recentBooks });
      }
  
      // // 새로운 메모의 책을 "최근에 메모가 추가된 책" 리스트에 추가합니다.
      // const recentBooksRef = doc(firestore, 'system', 'recentBooks');
      // const recentBooksDoc = await getDoc(recentBooksRef);
      // let recentBooks = [];
      // if (recentBooksDoc.exists()) {
      //   recentBooks = recentBooksDoc.data().books;
      // }
      // recentBooks.unshift(formData.book);  // 새 책을 리스트의 앞에 추가합니다.
      // if (recentBooks.length > 9) {
      //   recentBooks.pop();  // 리스트가 9개를 초과하면 가장 오래된 책을 제거합니다.
      // }
      // await setDoc(recentBooksRef, { books: recentBooks });
  
      alert('메모가 저장되었습니다.');
      navigate(`/memos`);
      setFormData({
        title: '',
        content: '',
        createTime: Timestamp.now().toDate(),
        likes: [],
        user: user ? user.uid : '',
        book: '',
        isBookListVisible: false,
      });
    } catch (err) {
      alert('Error adding memo');
    }
  };

  const handleCancel = () => {
    const confirmCancel = window.confirm('메모 작성/수정을 정말로 취소하시겠습니까?');
    if (confirmCancel) {
      navigate(`/memos`);
      setFormData({
        title: '',
        content: '',
        createTime: Timestamp.now().toDate(),
        likes: [],
        user: user ? user.uid : '',
        book: '',
        isBookListVisible: false,
      });
    }
  };

  

  const formatContent = (content) => {
    return content.replace(/<br>/g, '\n'); // <br> 태그를 줄바꿈 문자로 변경하여 표시
  };

  return (
    <Box sx={{ width: '100%', }}>
    <Box pl={2} pr={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: '100', }} bgcolor="#f8f8f8">
      <Box sx={{ display: 'flex', alignItems: 'center',}} >
        <Typography variant="body1" component="div" sx={{ fontWeight: 'bold',}}>
          메모 작성
        </Typography>
      </Box>
      <Box>
        <Button onClick={handleCancel} style={{ marginRight: 20, }}>취소</Button>
        <Button onClick={handlePublish} variant="contained" color="primary">저장</Button>
      </Box>
    </Box>
    <Box pb={2}>
      <Container>
        <Grid container spacing={2} className="book-section">
          <Grid item xs={12}>
            <Card
              variant=""
              sx={{
                backgroundColor: '#fefefe',
                transition: 'background-color 0.3s',
                borderRadius: 2,
                marginBottom: 2,
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TextField
                  type="text"
                  name="title"
                  variant="standard"
                  placeholder="제목을 입력하세요"
                  value={formData.title}
                  onChange={handleChange}
                  multiline
                  InputProps={{
                    sx: {
                      fontSize: isTablet ? '1.6rem':'1.5rem',
                      fontWeight: 800,
                      color: '#333',
                      marginBottom: '1rem',
                    },
                  }}
                />
                <TextField
                  name="content"
                  placeholder="생각 또는 내용을 입력하세요..."
                  variant="standard"
                  InputProps={{
                          disableUnderline: true,
                          sx: {
                            fontSize: isTablet ? '1.3rem': '1.2rem',
                            color: '#303030',
                            marginBottom: '3rem',
                            lineHeight: isTablet ? '2.2rem': '2rem',
                          },
                        }}
                  value={formData.content}
                  onChange={handleChange}
                  multiline
                  sx={{
                    minHeight: '200px',
                  }}
                />
                <Box>
                  <button onClick={handleBookSelect} variant="contained" color="secondary">
                    {formData.book ? `선택한 책: ${formData.bookTitle}` : '책 선택하기'}
                  </button>

                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {formData.isBookListVisible && <LikedBooks onSelect={handleBookChoice} />}
          </Grid>
        </Grid>
      </Container>
    </Box>
  </Box>
  );
}

