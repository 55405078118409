import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, googleProvider, firestore } from './Firebase';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signInWithRedirect, signOut } from 'firebase/auth'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  // const [user, setUser] = useState(null);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  const googleSignIn = async() => {
    try {
      await signInWithRedirect(auth, googleProvider);
    } catch(err) {
      console.error(err);
    }
  }

  // function googleSignIn() {
  //   return signInWithPopup(auth, googleProvider);
  // }

  // const [user, setUser] = useState(undefined);
  // const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null); // null로 초기화
  const [loading, setLoading] = useState(true); // true로 초기화
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // 프로필 이미지 URL 포함
        setUser({
          uid: currentUser.uid,
          email: currentUser.email,
          photoURL: currentUser.photoURL,
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, []);
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);
  //     setLoading(false);
  //   });

  //   // Clean up the listener on unmount
  //   return () => unsubscribe();
  // }, []);



  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);
  //     setLoading(false);
  //   });

  //   // Clean up the listener on unmount
  //   return () => unsubscribe();
  // }, []);


      // Add the modal state and functions to the context value
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
  
    return (
      <AuthContext.Provider
      value={{
        user,
        loading,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        isModalOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
    </AuthContext.Provider>
    );
  };

export function useUserAuth() {
  return useContext(AuthContext);
};