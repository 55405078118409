// LikedBooks.js
import React, { useEffect, useState } from 'react';
import { useUserAuth } from '../AuthProvider';
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { firestore } from '../Firebase';
import BookChoice from '../components/BookChoice';
import {   
  Box, Container, Grid, Typography, } from '@mui/material'

const LikedBooks = ({ onSelect }) => {
  const { user } = useUserAuth();
  const [books, setBooks] = useState([]);

  useEffect(() => {
    if (user) {
      const userRef = doc(firestore, "users", user.uid);

      const unsubscribe = onSnapshot(userRef, async (userSnapshot) => {
        if (userSnapshot.exists()) {
          const likedBooksIds = userSnapshot.data().likedBooks;

          if (Array.isArray(likedBooksIds)) {
            const likedBooks = await Promise.all(likedBooksIds.map(async (id) => {
              const bookSnapshot = await getDoc(doc(firestore, "books", id));
              return { id: bookSnapshot.id, ...bookSnapshot.data() };
            }));

            setBooks(likedBooks);
          }
        }
      });

      // Cleanup function
      return () => unsubscribe();
    }
  }, [user]);

  return (
    <div>
      <Typography
        variant="h6"
        component="div"
        sx={{
          fontWeight: 'bold',
          color: '#333',
          marginBottom: '1rem',
        }}
      >
        내 서재의 책들 중 한권을 선택하세요.
      </Typography>
      <Grid container spacing={1} className="book-section">
      {books.map((book) => (
        <Grid item xs={12} key={book.id}>
        <BookChoice key={book.id} {...book} onClick={() => onSelect(book)} />
        </Grid>
      ))}
      </Grid>
    </div>
  );
};

export default LikedBooks;


