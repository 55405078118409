import './App.css';
import { Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';

import {   
  Box, Container, Grid, Typography, useMediaQuery, useTheme, SvgIcon, Button, IconButton } from '@mui/material';

import logoSvg from './assets/img/logo.svg';
import blankAvatar from './assets/img/blankAvatar.png';

import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import QueueIcon from '@mui/icons-material/Queue';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import Home from './pages/Home';
import BookProfile from './pages/BookProfile';
import MemoDetail from './pages/MemoDetail';
import Search from './pages/Search';
import Likes from './pages/Likes';
import Memo from './pages/Memos';
import AddMemo from './pages/AddMemo';
import Test from './Test';


import { Auth } from './components/auth';
// import Login from './components/Login';
import LoginModal from './components/LoginModal';
import Signup from './components/Signup';

import { useUserAuth } from "./AuthProvider";
import PrivateRoute from './components/PrivateRoute';

import React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Helmet } from 'react-helmet-async';
import UserProfile from './pages/UserProfile';
import UserProfileEdit from './pages/UserProfileEdit';

function App() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));


  const navigate = useNavigate();

  const { logOut, user, openModal } = useUserAuth();

  const handleLogout = async () => {
    if (user) {
      try {
        await logOut();
        navigate("/");
      } catch (error) {
        console.log(error.message);
      }
    } else {
      console.log('No user is currently logged in');
    }
  };

  const handleLoginClick = () => {
    if (!user) {
      // navigate('/login');
      openModal();
    } else {
      console.log('User is already logged in');
    }
  };

  const handleAddMemo = () => {
    if (user) {
      navigate('/addmemo');
    } else {
      console.log('You need to log in to add a memo');
    }
  };

  const handleUserClick = () => {
    if (user) {
      navigate(`/users/${user.uid}`);
    } else {
      console.log('You are not user');
    }
  };


  return (
    <>
      <Helmet>
        <title>Kogito</title>
        <meta name="description" content="지식인을 위한 독서 메모 블로그. 코기토에 책별로 메모를 남기세요." />
        <link rel="canonical" href="https://kogitobooks.com" />
      </Helmet>
        <Box sx={{display: 'flex', justifyContent: 'center', }}>
        <Grid container maxWidth="xl">
          {/* 네비게이션 */}
          {isDesktop ? (
          <Grid pl={1} item xs={12} md={1} lg={2}>
            <Box sx={{ position: "sticky", top: 0, }}>
            <Link to='/'  style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
              // visited link color
              onClick={(e) => e.currentTarget.style.color = 'inherit'}>
            <Box pl={1} bgcolor="#fff" sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '60px'}} >
              <img src={logoSvg} alt="Logo"  style={{ width: 36, height: 36}} />
              {isDesktop && (
                <text style={{ fontSize: '30px', fontWeight: 700, marginLeft: 10}}>Kogito</text>
              )}
            </Box>
            </Link>

            {/* <Link to='/'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
              <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px', width: '70%' }, }} >
                <SvgIcon sx={{width: 28, height: 28}} component={ HomeIcon } />
                {isDesktop && (
                <text style={{ fontWeight: 600, fontSize: 20, marginLeft: 10}} >
                  홈
                </text>
                )}
              </Box>
            </Link> */}

            <Link to='/search'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
              <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px', width: '70%' },}} >
                <SvgIcon sx={{width: 28, height: 28}} component={ SearchIcon } />
                {isDesktop && (
                <text style={{ fontWeight: 600, fontSize: 20, marginLeft: 10}} >
                  책 검색
                </text>
                )}
              </Box>
            </Link>

            <Link to='/likes'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
              <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px', width: '70%' },}} >
                <SvgIcon sx={{width: 28, height: 28}} component={ FavoriteIcon } />
                {isDesktop && (
                <text style={{ fontWeight: 600, fontSize: 20, marginLeft: 10}} >
                  내 서재
                </text>
                )}
              </Box>
            </Link>


            <Link to='/memos'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
              <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px', width: '70%' },}} >
                <SvgIcon sx={{width: 28, height: 28}} component={ ArticleOutlinedIcon } />
                {isDesktop && (
                <text style={{ fontWeight: 600, fontSize: 20, marginLeft: 10}} >
                  내 메모
                </text>
                )}
              </Box>
            </Link>

            <Link to='/addmemo'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
              <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px', width: '70%' },}} >
                <SvgIcon sx={{width: 28, height: 28}} component={ AddBoxOutlinedIcon } />
                {isDesktop && (
                <text style={{ fontWeight: 600, fontSize: 20, marginLeft: 10}} >
                  메모 작성
                </text>
                )}
              </Box>
            </Link>
            </Box>

          </Grid>
          ):(
            <>
              <Grid pl={1} item xs={9}>
                <Box sx={{ position: "sticky", top: 0, display: 'flex',  }}>
                  <Link to='/' style={{ textDecoration: 'none'}}>
                  <Box pl={1} bgcolor="#fff" sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '60px', marginRight: '4px'}} >
                    <img src={logoSvg} alt="Logo"  style={{ width: 36, height: 36}} />
                  </Box>
                  </Link>
                  <Link to='/search'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
                    <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px'},}} >
                      <SvgIcon sx={{width: 28, height: 28}} component={ SearchIcon } />
                    </Box>
                  </Link>
                  <Link to='/likes'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
                    <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px'},}} >
                      <SvgIcon sx={{width: 28, height: 28}} component={ FavoriteIcon } />
                    </Box>
                  </Link>
                  <Link to='/memos'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
                    <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px'},}} >
                      <SvgIcon sx={{width: 28, height: 28}} component={ ArticleOutlinedIcon } />
                    </Box>
                  </Link>
                  <Link to='/addmemo'  style={{
    color: 'inherit',
    textDecoration: 'none',
  }}
  // visited link color
  onClick={(e) => e.currentTarget.style.color = 'inherit'}>
                    <Box p={2} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', '&:hover': { backgroundColor: '#f4f4f4', borderRadius: '10px'},}} >
                      <SvgIcon sx={{width: 28, height: 28}} component={ AddBoxOutlinedIcon } />
                    </Box>
                  </Link>
                </Box>
              </Grid>
              <Grid pr={1} item xs={3}>
                <Box pl={1} bgcolor="#fff" sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: "sticky", top: 0, height: '60px'}} >
                  {user? (
                  <>
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <img src={ blankAvatar } alt="Logo"  style={{ width: 40, height: 40, borderRadius: 999, border: '4px solid #eeeeee', }} />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleUserClick}>{user.email}</MenuItem>
                      <MenuItem onClick={handleAddMemo}>메모 작성</MenuItem>
                      <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
                    </Menu>
                  </>
                  ):(
                    <Button variant="outlined" onClick={handleLoginClick} sx={{ borderRadius: 3, color: "black",
                    borderColor: "gray",
                    ":hover": { borderColor: "black" },}}>로그인</Button>
                  )}
                </Box>
              </Grid>
            </>

          )}

          {/* 컨텐츠(컨테이너) */}
          <Grid item xs={12} lg={8}>
            <Box bgcolor="#f8f8f8" minHeight={'100vh'} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Routes>
                <Route path='/' exact={true} element={<Home />} />
                <Route path="/search"  exact={true} element={<Search />} />
                <Route path="/books/:id" exact={true} element={ <BookProfile /> } />
                <Route path="/memos/:id" exact={true} element={ <MemoDetail /> } />
                {/* <Route path="/login" exact={true} element={ <Login /> } /> */}
                <Route path="/signup" exact={true} element={ <Signup /> } />
                <Route path="/users/:id" exact={true} element={ <UserProfile /> } />
                <Route path="/profile/edit" exact={true} element={ <UserProfileEdit /> } />
                <Route 
                  path="/likes" 
                  element={ 
                    <PrivateRoute element={<Likes />} />
                  } 
                />
                <Route 
                  path="/memos" 
                  element={ 
                    <PrivateRoute element={<Memo />} />
                  } 
                />
                <Route 
                  path="/addmemo" 
                  element={ 
                    <PrivateRoute element={<AddMemo />} />
                  } 
                />
                <Route 
                  path="/addmemo/:id" 
                  element={ 
                    <PrivateRoute element={<AddMemo />} />
                  } 
                />
              </Routes>
              <LoginModal />
            </Box>
          </Grid>

          {/* 우측의 빈 공간 */}
          {isDesktop && (
            <Grid item pr={1} xs={12} md={1} lg={2}>
              <Box pl={1} pr={1} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: "sticky", top: 0, height: '60px'}} >
                {user? (
                    <>
                    <Button variant="outlined" onClick={handleAddMemo} sx={{ marginRight: 1, borderRadius:999, bgcolor: "white", color: "black",
                    borderColor: "gray",
                    ":hover": { bgcolor: "black", color: "white"},}}>메모 작성</Button>
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <img src={ blankAvatar } alt="Logo" style={{ width: 40, height: 40, borderRadius: 999, border: '4px solid #eeeeee', }} />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={handleUserClick}>{user.email}</MenuItem>
                      <MenuItem onClick={handleAddMemo}>메모 작성</MenuItem>
                      <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
                    </Menu>
                  </>
                ):(
                  <Button variant="contained" onClick={handleLoginClick} sx={{ borderRadius: 5, bgcolor: "black",
                  borderColor: "gray",
                  ":hover": { color: "black", bgcolor: "white" },}}>로그인</Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
    </Box>
    </>

  );
}

export default App;





