// import React from "react";
// import {   
//   Card,
//   CardMedia,
//   CardContent,
//   Typography } from '@mui/material';

// import { Link } from 'react-router-dom';

// function BookCard({ id, title, image, author, publisher, description, children }) {
// 	return (
//     <Link to={`/books/${id}`} state={{ id: id, title: title, image: image, author: author, publisher: publisher, description: description }} style={{ textDecoration: 'none' }}>
//       <Card
//         variant=""
//         sx={{
//           backgroundColor: '#f8f8f8',
//           transition: 'background-color 0.3s',
//           borderRadius: 2,
//           '&:hover': {
//             backgroundColor: '#fff',
//           },
//           position: 'relative',  // Add this line
//         }}
//       >
//         {children}
//         <CardMedia
//           component="img"
//           height='200'
//           src={image}
//           alt="bookimage"
//           sx={{ objectFit: 'contain', marginTop: 5, marginBottom: 1 }}
//         />
//         <CardContent>
//           <Typography
//             variant="h6"
//             component="div"
//             className="book-card__title"
//             noWrap
//             sx={{
//               fontSize: '1.2rem',
//               fontWeight: 'bold',
//               color: '#333',
//               marginBottom: '0.5rem',
//             }}
//           >
//             {title}
//           </Typography>
//           <Typography
//             variant="body2"
//             color="text.secondary"
//             sx={{
//               fontSize: '0.9rem',
//               color: '#666',
//               marginBottom: '0.5rem',
//             }}
//           >
//             {author} | {publisher}
//           </Typography>
//           <Typography
//             variant="body2"
//             color="text.secondary"
//             sx={{
//               fontSize: '0.9rem',
//               color: '#888',
//               overflow: 'hidden',
//               textOverflow: 'ellipsis',
//               display: '-webkit-box',
//               WebkitLineClamp: '3',
//               WebkitBoxOrient: 'vertical',
//             }}
//           >
//             {description}
//           </Typography>
//         </CardContent>
//       </Card>
//     </Link>
//   );
// }

// export default BookCard;

// BookCard.js
import React from "react";
import {   
  Card,
  CardMedia,
  CardContent,
  Typography } from '@mui/material';

import { Link } from 'react-router-dom';

function BookCard({ id, title, image, author, publisher, description, pubdate, isbn, children }) {
	return (
    <div style={{ position: 'relative' }}>
      <Link to={`/books/${id}`} state={{ id: id, title: title, image: image, author: author, publisher: publisher, description: description, pubdate: pubdate, isbn: isbn, }} style={{ textDecoration: 'none', display: 'block' }}> 
        <Card
          sx={{
            backgroundColor: '#fcfcfc',
            transition: 'background-color 0.3s',
            // borderRadius: 4,
            '&:hover': {
              backgroundColor: '#fff',
            },
            position: 'relative',
          }}
        >
          <CardMedia
            component="img"
            height='200'
            src={image}
            alt="bookimage"
            sx={{ objectFit: 'contain', marginTop: 5, marginBottom: 1 }}
          />
          <CardContent>
            <Typography
              variant="h6"
              component="div"
              className="book-card__title"
              noWrap
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: '#333',
                marginBottom: '0.5rem',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              noWrap
              sx={{
                fontSize: '0.9rem',
                color: '#666',
                marginBottom: '0.5rem',
              }}
            >
              {author} | {publisher}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '0.9rem',
                color: '#888',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {description}
            </Typography>
          </CardContent>
        </Card>
      </Link>
      {children}
    </div>
  );
}

export default BookCard;

// import React, { useState } from "react";
// import {   
//   Card,
//   CardMedia,
//   CardContent,
//   Typography } from '@mui/material';

// import { Link } from 'react-router-dom';

// function BookCard({ id, title, image, author, publisher, description, children }) {
//   const [isHovered, setIsHovered] = useState(false);

//   return (
//     <div 
//       style={{ position: 'relative' }} 
//       onMouseEnter={() => setIsHovered(true)} 
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       <Link to={`/books/${id}`} state={{ id: id, title: title, image: image, author: author, publisher: publisher, description: description }} style={{ textDecoration: 'none', display: 'block' }}>
//         <Card
//           variant=""
//           sx={{
//             backgroundColor: '#f8f8f8',
//             transition: 'background-color 0.3s',
//             borderRadius: 2,
//             '&:hover': {
//               backgroundColor: '#fff',
//             },
//             position: 'relative',
//           }}
//         >
//           <CardMedia
//             component="img"
//             height='200'
//             src={image}
//             alt="bookimage"
//             sx={{ objectFit: 'contain', marginTop: 5, marginBottom: 1 }}
//           />
//           <CardContent>
//             <Typography
//               variant="h6"
//               component="div"
//               className="book-card__title"
//               noWrap
//               sx={{
//                 fontSize: '1.2rem',
//                 fontWeight: 'bold',
//                 color: '#333',
//                 marginBottom: '0.5rem',
//               }}
//             >
//               {title}
//             </Typography>
//             <Typography
//               variant="body2"
//               color="text.secondary"
//               sx={{
//                 fontSize: '0.9rem',
//                 color: '#666',
//                 marginBottom: '0.5rem',
//               }}
//             >
//               {author} | {publisher}
//             </Typography>
//             <Typography
//               variant="body2"
//               color="text.secondary"
//               sx={{
//                 fontSize: '0.9rem',
//                 color: '#888',
//                 overflow: 'hidden',
//                 textOverflow: 'ellipsis',
//                 display: '-webkit-box',
//                 WebkitLineClamp: '3',
//                 WebkitBoxOrient: 'vertical',
//               }}
//             >
//               {description}
//             </Typography>
//           </CardContent>
//         </Card>
//       </Link>
//       {isHovered && children}
//     </div>
//   );
// }

// export default BookCard;
