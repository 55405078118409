import React, { useState, useEffect } from "react";
import {   
  Card,
  CardMedia,
  CardContent,
  Typography } from '@mui/material';

import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../Firebase';

const MemoCard = ({id, title, content, bookId}) => {

  const [bookTitle, setBookTitle] = useState('');

  useEffect(() => {
    
    const fetchBookTitle = async () => {
      const bookRef = doc(firestore, "books", bookId);
      const bookSnapshot = await getDoc(bookRef);

      if (bookSnapshot.exists()) {
        setBookTitle(bookSnapshot.data().title);
      }
    };

    fetchBookTitle();
  }, [bookId]);

	return (
      <Card
        variant=""
        sx={{
          backgroundColor: '#fff',
          transition: 'background-color 0.3s',
          // aspectRatio: '1 / 1',
          // borderRadius: 4,
          // '&:hover': {
          //   backgroundColor: '#fff',
          // },
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Link to={`/memos/${id}`}>
          <Typography
            variant="body1"
            component="div"
            sx={{
              fontSize: '1.3rem',
              fontWeight: 'bold',
              // color: '#333',
              marginBottom: '0.5rem',
              display: '-webkit-box',
              // overflow: 'hidden',
              // textOverflow: 'ellipsis',
              // WebkitLineClamp: '2',
              // WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
          </Link>
          <Typography
            variant="body2"
            sx={{
              fontSize: '1rem',
              overflow: 'hidden',
              color: '#888',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
              marginBottom: '1rem',
              lineHeight: '1.8rem',
            }}
          >
            {content?.split('\n').map((line, index) => (
              <span key={index}>{line}<br/></span>
            ))}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            fontStyle="italic"
            sx={{
              fontSize: '0.9rem',
              color: '#666',
              alignSelf: 'flex-end',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            <Link to={`/books/${bookId}`}>
            『{bookTitle}』
            </Link>
          </Typography>
        </CardContent>
      </Card>
  );
}

export default MemoCard;