import React, { useState, useEffect } from 'react';

import { CircularProgress, Grid, useMediaQuery, IconButton, Button, Box, Typography, Container, Card, CardMedia, CardContent, SvgIcon } from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import blankAvatar from '../assets/img/blankAvatar.png';
import { useTheme } from '@mui/material/styles';
import { useUserAuth } from "../AuthProvider";
import { firestore } from '../Firebase';
import { getDoc, deleteDoc, doc } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import BookChoice from '../components/BookChoice';


const MemoDetail = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useUserAuth();
  const [like, setLike] = useState(false);

  const handleLike = () => {
    if (like) {
      const confirmCancel = window.confirm('좋아요를 정말로 취소하시겠습니까?');
      if (confirmCancel) {
        setLike(false);
      }
    } else {
      setLike(true);
    }
  };

  const [memo, setMemo] = useState(null);
  const [authorNickname, setAuthorNickname] = useState('');
  // const [userEmail, setUserEmail] = useState('');
  const [bookTitle, setBookTitle] = useState('');
  const [book, setBook] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchMemoAndBook = async () => {
      const memoRef = doc(firestore, 'memos', id);
      const memoDoc = await getDoc(memoRef);

      if (memoDoc.exists()) {
        const memoData = memoDoc.data();
        setMemo(memoData);

        // Fetch the author's nickname
        const userRef = doc(firestore, 'users', memoData.user);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setAuthorNickname(userDoc.data().nickName || memoData.user);  // Use uid as a fallback
        }

        const bookRef = doc(firestore, 'books', memoData.book);
        const bookDoc = await getDoc(bookRef);
        if (bookDoc.exists()) {
          setBookTitle(bookDoc.data().title);
          setBook(bookDoc.data());  // Store the entire book data
        }
        setLoading(false); 
      }
    };

    fetchMemoAndBook();
  }, [id]); // Depend on id so it runs every time the id changes

  if (loading) {  // 로딩 중일 때 CircularProgress 컴포넌트 보여주기
    return <CircularProgress />;
  }

  const handleDelete = async () => {
    const confirmDelete = window.confirm('메모를 정말로 삭제하시겠습니까?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(firestore, 'memos', id));
        alert('메모가 삭제되었습니다.');
        navigate(`/memos`);
        // 여기서 사용자를 메모 목록 페이지로 리다이렉트할 수 있습니다.
      } catch (err) {
        alert('Error deleting memo');
      }
    }
  };

  const handleEdit = () => {
    navigate(`/addmemo/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>{memo?.title}</title>
        <meta name="description" content={memo?.content} />
        <link rel="canonical" href={`https://kogitobooks.com/memos/${id}`}  />
      </Helmet>
      <Box sx={{ width: '100%', }}>
        <Box pl={2} pr={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: '100', }} bgcolor="#f8f8f8">
          <Box sx={{ display: 'flex', alignItems: 'center',}} >
            <Link 
              to={`/users/${memo?.user}`} 
              style={{
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
              // visited link color
              onClick={(e) => e.currentTarget.style.color = 'inherit'}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row',}} >
                <img src={ blankAvatar } alt="Logo"  style={{ width: 40, height: 40, borderRadius: 999, border: '4px solid #eeeeee', }} />
                <Box ml={0.5} mt={0.2}>
                  <Typography variant="body1" component="div" sx={{ fontSize: '1.1rem', fontWeight: 'bold'}} >
                    {authorNickname?.length > 18 ? `${authorNickname?.slice(0, 18)}...` : authorNickname}
                  </Typography>
                  <Typography variant="body2" component="div" sx={{ fontSize: '0.8rem', color: '#666' }} >
                    {memo?.createTime.toDate().toLocaleDateString()}
                  </Typography>
                </Box>
              </Box>
            </Link>
            {/* <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                    fontSize: '0.8rem',
                    color: '#888',
                    marginLeft: 1,
                  }}
            >
              · 2023.02.28.
            </Typography> */}
          </Box>

          <Box sx={{display: 'flex',}}>
          {user && user.uid === memo?.user && (
            <>
              <IconButton sx={{ marginRight: 1,}} onClick={handleEdit}>
                <SvgIcon sx={{ width: 24, height: 24, }} component={EditIcon} />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <SvgIcon sx={{ width: 24, height: 24, }} component={DeleteIcon} />
              </IconButton>
            </>
          )}
          </Box>
          {/* <IconButton color="secondary" aria-label="favorite" sx={{ position: 'relative', zIndex: 1 }}>
            {user && likes.includes(user.uid) ? (
              <SvgIcon
                sx={{ width: 36, height: 36, color: "#db4455" }}
                component={FavoriteIcon}
              />
            ) : (
              <SvgIcon
                sx={{ width: 36, height: 36, color: "#bbb" }}
                component={FavoriteBorderIcon}
              />
            )}
          </IconButton> */}


        </Box>
        <Box pb={2}>
          <Container>
            <Grid container spacing={2} className="book-section">
              <Grid item xs={12}>
                <Card
                  variant=""
                  sx={{
                    backgroundColor: '#fff',
                    transition: 'background-color 0.3s',
                    borderRadius: 2,
                    marginBottom: 2,
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: isTablet ? '1.6rem':'1.5rem',
                        fontWeight: 800,
                        color: '#333',
                        marginBottom: '1rem',
                      }}
                    >
                      {memo?.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: isTablet ? '1.3rem': '1.2rem',
                        color: '#303030',
                        marginBottom: '3rem',
                        lineHeight: isTablet ? '2.2rem': '2rem',
                      }}
                    >
                      {memo?.content.split('\n').map((line, index) => (
                        <span key={index}>{line}<br/></span>
                      ))}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontStyle="italic"
                      sx={{
                        fontSize: '1rem',
                        color: '#666',
                        alignSelf: 'flex-end',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      <Link 
                        to={`/books/${memo?.book}`}
                        style={{
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                        onClick={(e) => e.currentTarget.style.color = 'inherit'}
                      >
                        『{bookTitle}』
                      </Link>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} key={memo?.book}>
                <Link to={`/books/${memo?.book}`}>
                  <BookChoice key={memo?.book} {...book} />
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default MemoDetail;