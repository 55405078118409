import React from "react";
import {   
  Card,
  CardMedia,
  CardContent,
  Typography } from '@mui/material';

import { Link } from 'react-router-dom';

const DescriptionCard = ({id, title, content, book}) => {
	return (
    <Link to={`/memos/${id}`} style={{ textDecoration: 'none' }}>
      <Card
        variant=""
        sx={{
          backgroundColor: '#f8f8f8',
          transition: 'background-color 0.3s',
          borderRadius: 4,
          '&:hover': {
            backgroundColor: '#fff',
          },
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: '#333',
              marginBottom: '0.5rem',
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '1.0rem',
              overflow: 'hidden',
              color: '#404040',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '6',
              WebkitBoxOrient: 'vertical',
              marginBottom: '0.5rem',
              lineHeight: '1.7rem',
            }}
          >
            {content}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            fontStyle="italic"
            sx={{
              fontSize: '0.9rem',
              color: '#666',
              alignSelf: 'flex-end',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
          >
             『{book}』
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}

export default DescriptionCard;