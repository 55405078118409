import React, { useState, useEffect } from "react";

import { Grid, Button, Box, Typography, Container, CircularProgress } from '@mui/material';

import { collection, query, where, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';

import MemoCard from "../components/MemoCard";
import { useUserAuth } from "../AuthProvider";
import { firestore } from "../Firebase";

const Memo = () => {
  const { user } = useUserAuth();
  const [memos, setMemos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMemos = async () => {
      if (!user) return; // If user is undefined, don't do anything.
  
      const memosRef = collection(firestore, "memos");
      const q = query(memosRef, where("user", "==", user.uid), orderBy("createTime"));
      const querySnapshot = await getDocs(q);
  
      const memosData = await Promise.all(
        querySnapshot.docs.map(async (memoDoc) => {
          const memoData = memoDoc.data();
  
          return { ...memoData, id: memoDoc.id };
        })
      );
  
      setMemos(memosData.reverse());  // Reverse the array to have the newest memo on top
      setLoading(false); // Set loading to false after fetching data
    };
  
    fetchMemos();
  }, [user]);

  
  
  
  
  
  
  

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: '100%', }}>
      <Box pl={2} pr={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: '100',}} bgcolor="#f8f8f8">
        <Typography variant="body1" component="div" sx={{ fontWeight: 'bold',}}>
          내 메모
        </Typography>
        {/* <Box>
          <Button style={{ marginRight: 20, }}>수정</Button>
          <Button variant="contained" color="secondary">삭제</Button>
        </Box> */}
      </Box>
      <Box pb={2}>
        <Container>
        <Grid container spacing={1} className="book-section">
        {memos.map((memo) => (
          <Grid item xs={12} key={memo.id}>
            <MemoCard
              id={memo.id}
              title={memo.title}
              content={memo.content}
              bookId={memo.book}
            />
          </Grid>
        ))}
        </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Memo;

