import React from "react";
// import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AspectRatio from '@mui/joy/AspectRatio';



import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

// const SkeletonBookList = () => {
//   <Card
//     variant=''
//     sx={{
//       display: 'flex',
//       alignItems: 'center',
//       backgroundColor: '#f8f8f8',
//       height: '200',
//       borderRadius: 4,
//     }}
//   >
//     <Skeleton variant="rectangular" width={'180px'} height={'180px'} sx={{ margin: 2, marginRight: 0, borderRadius: 5, }}/>
//     <Box sx={{ width: 600, marginTop: 2, marginBottom: 2,}}>
//       <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
//       <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
//       <Skeleton variant="text" sx={{ height: 40, marginLeft: 5, marginRight: 5,}} />
//     </Box>
//   </Card>
// };
// export default SkeletonBookList;

const BookList = ({id, title, image, author, publisher, description, pubdate, isbn}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Link to={`/books/${id}`} state={{ id: id, title: title, image: image, author: author, publisher: publisher, description: description, pubdate: pubdate, isbn: isbn, }} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fcfcfc',
          height: isTablet ? '200' : '180',
          // borderRadius: 4,
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#fff',
          },
        }}
      >
      <CardMedia
        component="img"
        src={image}
        alt="bookimage"
        sx={{
          objectFit: 'contain',
          margin: 2,
          marginRight: 0,
          height: isTablet ? 200 : '150px', // 삼항 연산자를 이용하여 isTablet 여부에 따라 높이 설정
          width: isTablet ? 200 : '150px',  // 삼항 연산자를 이용하여 isTablet 여부에 따라 너비 설정
        }}
      />
      <CardContent
        sx={{ width: 600, marginTop: 2, marginBottom: 2,}}
      >
        <Typography
          variant="h6"
          component="div"
          className="book-card__title"
          sx={{
            fontSize: isTablet ? '1.2rem' : '1.1rem',
            fontWeight: 'bold',
            color: '#333',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            marginBottom: '1rem',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: '0.9rem',
            color: '#666',
            marginBottom: '1rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {author} | {publisher}
        </Typography>
        {isTablet ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontSize: '0.9rem',
              color: '#888',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '4',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {description}
          </Typography>
        ):(
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '0.7rem',
                color: '#999',
                marginBottom: '0.4rem',
              }}
            >
              발행일: {pubdate}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '0.7rem',
                color: '#999',
              }}
            >
              ISBN: {isbn}
            </Typography>
          </>
        )}

      </CardContent>
    </Card>
  </Link>
  )
};

BookList.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  publisher: PropTypes.string.isRequired

};

export default BookList;