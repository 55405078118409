import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, TextField, Button, Typography, Box } from '@mui/material';
import { auth, firestore } from '../Firebase';
import { useUserAuth } from '../AuthProvider';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const UserProfileEdit = () => {
  const { user } = useUserAuth(auth);
  const [nickname, setNickname] = useState(user.uid);
  const [introduce, setIntroduce] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const userRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setNickname(userData.nickName || user.uid);
        setIntroduce(userData.introduce || '');
      }
    };

    fetchUser();
  }, [user]);

  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const handleIntroduceChange = (e) => {
    setIntroduce(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userRef = doc(firestore, 'users', user.uid);
    await setDoc(userRef, { nickName: nickname, introduce: introduce }, { merge: true });

    alert('Profile updated successfully');
    navigate(`/users/${user.uid}`);
  };

  return (
    <Box sx={{ width: '100%', }}>
      <Box pl={2} pr={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: '100', }} bgcolor="#f2f2f2">
        <Box sx={{ display: 'flex', alignItems: 'center',}} >
          <Typography variant="body1" component="div" sx={{ fontWeight: 'bold',}}>
            프로필 수정
          </Typography>
        </Box>
        <Box>
          <Button onClick={handleSubmit} variant="contained" color="primary">저장</Button>
        </Box>
      </Box>
      <Box pb={2}>
        <Container>
          <Grid container spacing={2} className="book-section">
            <Grid item xs={12}>
              <Card
                variant=""
                sx={{
                  backgroundColor: '#f8f8f8',
                  transition: 'background-color 0.3s',
                  borderRadius: 2,
                  marginBottom: 2,
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    name="nickname"
                    label="닉네임"
                    value={nickname}
                    onChange={handleNicknameChange}
                    sx={{ marginBottom: 4,}}
                    required
                  />
                  <TextField
                    name="introduce"
                    label="한줄소개"
                    value={introduce}
                    onChange={handleIntroduceChange}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default UserProfileEdit;