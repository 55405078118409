import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Modal, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from "../AuthProvider";
import { firestore } from '../Firebase';
import { collection, doc, setDoc } from 'firebase/firestore';



import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const Signup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { signUp } = useUserAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

      // Check if password and password confirmation match
    if (password !== confirmPassword) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    }

    
    try {
      // await signUp(email, password);
      const { user } = await signUp(email, password);

      // After signing up, create a document in the users collection with the same ID as the user's uid
      const userRef = doc(firestore, "users", user.uid);
      await setDoc(userRef, { likedBooks: [] });

      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClose = () => {
    navigate(-1); // 이전 화면으로 이동
  };

  const handleLoginClick = () => {
    navigate('/login'); // "/login" 링크로 이동
  };

  return (
    <Modal open onClose={handleClose}>
      <Box sx={modalStyle}>
        <Button sx={{ position: 'absolute', top: 0, left: 0 }} onClick={handleClose}>
          <SvgIcon sx={{width: 20, height: 20}} component={ ArrowBackIcon } />
        </Button>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 1 }}>
          회원가입
        </Typography>
        <TextField onChange={(e) => setEmail(e.target.value)} type="email" size="small" placeholder="이메일" sx={{ mb: 1, width: '80%' }} />
        <TextField onChange={(e) => setPassword(e.target.value)} type="password" size="small" placeholder="비밀번호" sx={{ mb: 1, width: '80%' }} />
        <TextField onChange={(e) => setConfirmPassword(e.target.value)} type="password" size="small" placeholder="비밀번호 확인" sx={{ mb: 1, width: '80%' }} />
        <Button onClick={handleSubmit} variant="contained" sx={{ mb: 1, width: '80%' }}>시작하기</Button>
        {error && 
                <Typography id="modal-modal-description" sx={{ color: 'red' }}>
                  {error}
                </Typography>}
        <Button>구글 계정으로 시작하기</Button>
        <Button onClick={handleLoginClick}>계정이 있으십니까? 로그인하기</Button>
      </Box>
    </Modal>
  );
};

export default Signup;