import { auth, googleProvider } from '../Firebase';
import React from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut, singOut } from 'firebase/auth'
import { useState } from 'react';
import { Button, Modal, Box, TextField, Typography } from '@mui/material';

const  modalStyle  = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

};


export const Auth = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log(auth?.currentUser?.email);

  const register = async() => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
    }
  };

  const signIn = async() => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
    }
  };

  const signInWithGoogle = async() => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async() => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>로그인</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ modalStyle }>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 1,}}>
            로그인
          </Typography>
          <TextField 
            type='email'
            size="small"
            placeholder="이메일"
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 1, width: '80%',}}
          />
          <TextField
            type='password'
            size="small"
            placeholder='비밀번호'
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 1, width: '80%',}}
          />
          <Button 
            sx={{ mb: 1, width: '80%',}}
            onClick={signIn}
          >
            로그인
          </Button>
          <Typography id="modal-modal-description" sx={{ color: 'red', }}>
            Error Code
          </Typography>
          <Button 
            onClick={signInWithGoogle}
          >
            구글로 로그인하기
          </Button>
          <Button 
            onClick={register}
          >
            회원가입
          </Button>
          <Button 
            onClick={logout}
          >
            로그아웃
          </Button>
          <Button>계정이 없으십니까? 회원가입하기</Button>
        </Box>
      </Modal>
      {/* <input 
      placeholder="Email..."
      onChange={(e) => setEmail(e.target.value)}
      />
      <input 
      placeholder="Password..."
      type='password'
      onChange={(e) => setPassword(e.target.value)}
      />
      <Button onClick={signIn}>Sign In</Button>

      <Button onClick={signInWithGoogle}>Sign In with Google</Button>

      <Button onClick={logout}>Logout</Button> */}
    </div>
  )
}