import React, { useState, useEffect } from 'react';

import { Grid, useMediaQuery, IconButton, Button, Box, Typography, Container, Card, CardMedia, CardContent, SvgIcon } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';

import DescriptionCard from '../components/DescriptionCard';
import { useUserAuth } from "../AuthProvider";
import { firestore } from '../Firebase';
import { useTheme } from '@mui/material/styles';
import { getDocs, query, collection, where, getDoc, setDoc, doc, runTransaction } from 'firebase/firestore';
import MemoCard from '../components/MemoCard';

const BookProfile = ({likes: initialLikes}) => {
  const { id } = useParams();
  const { user, openModal } = useUserAuth();
  const [likes, setLikes] = useState(initialLikes || []);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));

  const navigate = useNavigate();

  const [memos, setMemos] = useState([]);

  const [book, setBook] = useState(null);

  const [isExpanded, setIsExpanded] = useState(false);  // state for expanding or collapsing the description

  useEffect(() => {
    const fetchBookData = async () => {
      const bookRef = doc(firestore, "books", id);
      const bookSnapshot = await getDoc(bookRef);

      if (bookSnapshot.exists()) {
        setBook(bookSnapshot.data());
        setLikes(bookSnapshot.data().likes || []);
      }
    };

    const fetchMemos = async () => {
      const memosRef = collection(firestore, 'memos');
      const q = query(memosRef, where('book', '==', id));
      const querySnapshot = await getDocs(q);
      const memosData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMemos(memosData);
    };

    fetchBookData();
    fetchMemos();
  }, [id]);

  // const handleLike = () => {
  //   if (like) {
  //     const confirmCancel = window.confirm('좋아요를 정말로 취소하시겠습니까?');
  //     if (confirmCancel) {
  //       setLike(false);
  //     }
  //   } else {
  //     setLike(true);
  //   }
  // };

  const handleLike = async () => {
    if (user) {
      const bookRef = doc(firestore, "books", id);
      const userRef = doc(firestore, "users", user.uid);

      // Check if the user document exists, if not create one
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        await setDoc(userRef, { likedBooks: [] });
      }
  
      const bookSnapshot = await getDoc(bookRef);
      if (!bookSnapshot.exists()) {
        // If the book does not exist in Firestore, create a new document
        await setDoc(bookRef, {
          title: location.state.title,
          image: location.state.image,
          author: location.state.author,
          publisher: location.state.publisher,
          description: location.state.description,
          pubdate: location.state.pubdate,
          isbn: location.state.isbn,
          likes: [],
        });
      } 
      // else {
      //   // If the book exists in Firestore, update the likes field
      //   const likes = bookSnapshot.data().likes || [];
  
      //   if (likes.includes(user.uid)) {
      //     // If the user has already liked the book, remove their like
      //     await updateDoc(bookRef, {
      //       likes: arrayRemove(user.uid),
      //     });
      //   } else {
      //     // If the user has not liked the book, add their like
      //     await updateDoc(bookRef, {
      //       likes: arrayUnion(user.uid),
      //     });
      //   }
      // }

      // Begin a new transaction
      const newLikes = await runTransaction(firestore, async (transaction) => {
        // Get the user document
        const userDoc = await transaction.get(userRef);
  
        // Get the book document
        const bookDoc = await transaction.get(bookRef);
  
        let userLikedBooks = userDoc.data().likedBooks ?? [];
        let bookLikes = bookDoc.data().likes ?? [];
  
        if (likes.includes(user.uid)) {

                  // If the user has already liked the book, ask them to confirm unliking
          const confirmUnlike = window.confirm("정말로 좋아요를 취소하시겠습니까?");
          if (!confirmUnlike) {
            // If the user doesn't confirm, don't proceed with unliking
            return bookLikes;
          }
          // If the user has already liked the book, unlike it
          userLikedBooks = userLikedBooks.filter(bookId => bookId !== id);
          bookLikes = bookLikes.filter(uid => uid !== user.uid);
        } else {
          // If the user has not liked the book, like it
          userLikedBooks.push(id);
          bookLikes.push(user.uid);
        }
  
        // Update the user's liked books
        transaction.update(userRef, { likedBooks: userLikedBooks });
  
        // Update the book's likes
        transaction.update(bookRef, { likes: bookLikes });
        return bookLikes;
      });
      // Update the likes state
      setLikes(newLikes);
    } else {
      openModal();
    }
  };
  

  const location = useLocation();

  const bookData = location.state || book;

  // 북 프로필 화면 구성과 관련된 로직 및 JSX 작성

  return (
    <>
      <Helmet>
        <title>{bookData?.title}</title>
        <meta name="description" content={bookData?.description} />
        <link rel="canonical" href={`https://kogitobooks.com/books/${id}`}  />
      </Helmet>
      <Box sx={{ width: '100%', }}>
        <Box pl={2} pr={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: '100', }} bgcolor="#f8f8f8">
          <Typography variant="body1" component="div"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {bookData?.title}
          </Typography>
          {/* <IconButton onClick={handleLike} color="secondary" aria-label="favorite">
            {like ? (<SvgIcon sx={{width: 36, height: 36, color: '#ee0000', }} component={ FavoriteIcon } />):(<SvgIcon sx={{width: 36, height: 36, color: '#888', }} component={ FavoriteBorderIcon } />)}
          </IconButton> */}
          <IconButton onClick={handleLike} color="secondary" aria-label="favorite" sx={{ position: 'relative', zIndex: 1 }}>
            {user && likes.includes(user.uid) ? (
              <SvgIcon
                sx={{ width: 36, height: 36, color: "#db4455" }}
                component={FavoriteIcon}
              />
            ) : (
              <SvgIcon
                sx={{ width: 36, height: 36, color: "#bbb" }}
                component={FavoriteBorderIcon}
              />
            )}
          </IconButton>


        </Box>
        <Box pb={2}>
          <Container>
            <Grid container spacing={2} className="book-section">
              <Grid item lg={12}>
              <Card
              variant=""
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fff',
                height: '200',
                borderRadius: 2,
                marginBottom: 2,
              }}
            >
              <CardMedia
                component="img"
                src={bookData?.image}
                alt="image"
                sx={{ objectFit: 'contain', margin: 2, marginRight: 0, width: isTablet ? 220:160, height: isTablet ? 220:160, }}
              />
              <CardContent
                sx={{ marginTop: 2, marginBottom: 2, }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  className="book-card__title"
                  sx={{
                    fontSize: isTablet ? '1.6rem':'1.2rem',
                    fontWeight: 'bold',
                    color: '#333',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    marginBottom: '1rem',
                  }}
                >
                  {bookData?.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: isTablet ? '1.0rem': '0.8rem',
                    color: '#666',
                    marginBottom: isTablet ? '1rem': '0.8rem',
                  }}
                >
                  {bookData?.author} | {bookData?.publisher}
                </Typography>              
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: isTablet ? '0.9rem': '0.8rem',
                    color: '#666',
                    marginBottom: isTablet ? '0.9rem': '0.7rem',
                  }}
                >
                  발행일: {bookData?.pubdate}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: isTablet ? '0.9rem': '0.8rem',
                    color: '#666',
                    marginBottom: isTablet ? '0.9rem': '0.7rem',
                  }}
                >
                  ISBN: {bookData?.isbn}
                </Typography>
              </CardContent>
            </Card>
            <Card
                  variant=""
                  sx={{
                    backgroundColor: '#fff',
                    transition: 'background-color 0.3s',
                    borderRadius: 2,
                    marginBottom: 4,
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: '1.3rem',
                        fontWeight: 'bold',
                        color: '#333',
                        marginBottom: '0.5rem',
                      }}
                    >
                      책 소개
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '1.1rem',
                        color: '#404040',
                        marginBottom: '0.5rem',
                        lineHeight: '1.8rem',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: isExpanded ? undefined : '3',  // show all lines if expanded
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {bookData?.description.split('\n').map((line, index) => (
                        <span key={index}>{line}<br/></span>
                      ))}
                    </Typography>
                    <Button variant='none' onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? '접기' : '더 보기'}</Button>
                  </CardContent>
                </Card>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontWeight: 'bold',
                        color: '#333',
                        marginBottom: '1rem',
                      }}
                    >
                      유저가 남긴 메모
                    </Typography>
                    <Grid container spacing={2} className="book-section">
                    {memos.map((memo, index) => (
                      <Grid item xs={12} key={index}>
                        <MemoCard
                          id={memo.id}
                          title={memo.title}
                          content={memo.content}
                          bookId={memo.book}
                        />
                      </Grid>
                    ))}
                    </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default BookProfile;