// import React, { useState, useEffect } from 'react';
// import { Box, Button, Typography, Container, Grid, } from '@mui/material';
// import BookCard from '../components/BookCard';
// import { auth, firestore } from '../Firebase';
// import { arrayRemove, onSnapshot, getDoc, doc, updateDoc } from "firebase/firestore";

// import { useUserAuth } from '../AuthProvider';
// import LikeBook from '../components/LikeBook';

// const Likes = () => {

//   const [books, setBooks] = useState([]);
//   const {user} = useUserAuth(auth);

//   useEffect(() => {
//     if (user) {
//       const userRef = doc(firestore, "users", user.uid);

//       const unsubscribe = onSnapshot(userRef, async (userSnapshot) => {
//         if (userSnapshot.exists()) {
//           const likedBooksIds = userSnapshot.data().likedBooks;

//           if (likedBooksIds) {
//             const likedBooks = await Promise.all(likedBooksIds.map(async (id) => {
//               const bookSnapshot = await getDoc(doc(firestore, "books", id));
//               return { id: bookSnapshot.id, ...bookSnapshot.data() };
//             }));

//             setBooks(likedBooks);
//           }
//         }
//       });

//       // Cleanup function
//       return () => unsubscribe();
//     }
//   }, [user]);

//   return (
//     <Box sx={{ width: '100%', }}>
//       <Box pl={2} sx={{ position: "sticky", top: 0, height: '60px', display: 'flex', alignItems: 'center', zIndex: '100', }} bgcolor="#f2f2f2">
//         <Typography variant="body1" component="div">
//           내 저장
//         </Typography>
//       </Box>
//       <Box pb={2}>
//         <Container>
//         <Grid container spacing={2} className="book-section">
//         {books.map((book) => (
//           <Grid item xs={12} sm={6} md={4} lg={4} key={book.id}>
//             <BookCard
//               id={book.id}
//               title={book.title}
//               image={book.image}
//               author={book.author}
//               publisher={book.publisher}
//               description={book.description}
//               isbn={book.isbn}
//               pubdate={book.pubdate}
//             >
//             {user && (
//           <div style={{ position: 'absolute', top: 5, right: 5 }}>
//             <LikeBook id={book.isbn} likes={book.likes} />
//           </div>)}
//           </BookCard>
//           </Grid>
//         ))}
//         </Grid>
//         </Container>
//       </Box>
//     </Box>
//   );
// };

// export default Likes;

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Container, Grid } from '@mui/material';
import BookCard from '../components/BookCard';
import { auth, firestore } from '../Firebase';
import { arrayRemove, onSnapshot, getDoc, doc, updateDoc } from 'firebase/firestore';

import { useUserAuth } from '../AuthProvider';
import LikeBook from '../components/LikeBook';

const Likes = () => {
  const [books, setBooks] = useState([]);
  const { user } = useUserAuth(auth);

  useEffect(() => {
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);

      const unsubscribe = onSnapshot(userRef, async (userSnapshot) => {
        if (userSnapshot.exists()) {
          const likedBooksIds = userSnapshot.data().likedBooks;

          if (likedBooksIds) {
            const likedBooks = await Promise.all(
              likedBooksIds.map(async (id) => {
                const bookSnapshot = await getDoc(doc(firestore, 'books', id));
                return { id: bookSnapshot.id, ...bookSnapshot.data() };
              })
            );

            setBooks(likedBooks);
          }
        }
      });

      // Cleanup function
      return () => unsubscribe();
    }
  }, [user]);

  // Reverse the books array to display the latest liked books first
  const reversedBooks = [...books].reverse();

  return (
    <Box sx={{ width: '100%' }}>
      <Box pl={2} sx={{ position: 'sticky', top: 0, height: '60px', display: 'flex', alignItems: 'center', zIndex: '100' }} bgcolor="#f8f8f8">
        <Typography variant="body1" component="div" sx={{ fontWeight: 'bold',}}>
          내 서재
        </Typography>
      </Box>
      <Box pb={2}>
        <Container>
          <Grid container spacing={1} className="book-section">
            {reversedBooks.map((book) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={book.id}>
                <BookCard
                  id={book.id}
                  title={book.title}
                  image={book.image}
                  author={book.author}
                  publisher={book.publisher}
                  description={book.description}
                  isbn={book.isbn}
                  pubdate={book.pubdate}
                >
                  {user && (
                    <div style={{ position: 'absolute', top: 5, right: 5 }}>
                      <LikeBook id={book.isbn} likes={book.likes} />
                    </div>
                  )}
                </BookCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Likes;