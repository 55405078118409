// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useUserAuth } from "../AuthProvider";
// const PrivateRoute = ({ children }) => {
//   const { user } = useUserAuth();

//   console.log("Check user in Private: ", user);
//   if (!user) {
//     return <Navigate to="/login" />;
//   }
//   return children;
// };

// export default PrivateRoute;

// PrivateRoute.js
import React from 'react';
import { useUserAuth } from '../AuthProvider';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, ...props }) => {
  const { user, loading, openModal } = useUserAuth();

  if (loading) {
    return <div>Loading...</div>;  // Or your own loading component
  }

  if (!user) {
    openModal();
    return <Navigate to="/" replace />;
  }

  return React.cloneElement(element, props);
};

export default PrivateRoute;


// import { Navigate } from "react-router-dom";
// import { useUserAuth } from "../AuthProvider";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user, loading, openModal } = useUserAuth();

//   if (loading) {
//     return <div>Loading...</div>;  // Or your own loading component
//   }

//   if (!user) {
//     openModal();
//     return <Navigate to="/login" replace />;
//     // return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default PrivateRoute;