// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqFx2UGjo2TLTkdEcg1xpXSzCsWkkVL9o",
  // authDomain: "kogito-86482.firebaseapp.com",
  authDomain: "kogitobooks.com",
  projectId: "kogito-86482",
  storageBucket: "kogito-86482.appspot.com",
  messagingSenderId: "594166312216",
  appId: "1:594166312216:web:215a87b871d5673b5f826d",
  measurementId: "G-TMKJYVKP1V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = getFirestore(app);