import React, { useEffect, useState } from 'react';
import { useUserAuth } from "../AuthProvider";
import { auth, firestore } from "../Firebase";
import { IconButton, SvgIcon } from '@mui/material';
import { arrayRemove, arrayUnion, setDoc, getDoc, doc, runTransaction, onSnapshot } from "firebase/firestore";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';

const LikeBook = ({ id, likes }) => {
  const {user, openModal} = useUserAuth(auth);
  const [isLiked, setIsLiked] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const bookRef = doc(firestore, 'books', id);
    const unsubscribe = onSnapshot(bookRef, (doc) => {
      if (user) {
        setIsLiked(doc.data().likes.includes(user.uid));
      } else {
        setIsLiked(false);
      }
    });

    return () => unsubscribe();
  }, [id, user]);

  const handleLike = async() => {
    // event.stopPropagation();  // Prevent event bubbling
    // if (user) {  // Check if user is not null
    //   if (!likes) {
    //     updateDoc(likeRef, {
    //       likes: [user.uid],
    //     }).catch((e) => {
    //       console.log(e);
    //     });
    //   } else if (likes.includes(user.uid)) {
    //     updateDoc(likeRef, {
    //       likes: arrayRemove(user.uid),
    //     }).catch((e) => {
    //       console.log(e);
    //     });
    //   } else {
    //     updateDoc(likeRef, {
    //       likes: arrayUnion(user.uid),
    //     }).catch((e) => {
    //       console.log(e);
    //     });
    //   }
    // }
    if (user) {  // Check if user is not null
      const userRef = doc(firestore, "users", user.uid);
      const bookRef = doc(firestore, "books", id);
  
      // Check if the user document exists, if not create one
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        await setDoc(userRef, { likedBooks: [] });
      }
  
      // Check if the book document exists, if not create one
      const bookDoc = await getDoc(bookRef);
      if (!bookDoc.exists()) {
        await setDoc(bookRef, { likes: [] });
      }
  
      // Begin a new transaction
      await runTransaction(firestore, async (transaction) => {
        // Get the user document
        const userDoc = await transaction.get(userRef);
  
        // Get the book document
        const bookDoc = await transaction.get(bookRef);
  
        let userLikedBooks = userDoc.data().likedBooks ?? [];
        let bookLikes = bookDoc.data().likes ?? [];
  
        if (isLiked) {

          // If the user has already liked the book, ask them to confirm unliking
          const confirmUnlike = window.confirm("정말로 좋아요를 취소하시겠습니까?");
          if (!confirmUnlike) {
            // If the user doesn't confirm, don't proceed with unliking
            return bookLikes;
          }
          // If the user has already liked the book, unlike it
          userLikedBooks = userLikedBooks.filter(bookId => bookId !== id);
          bookLikes = bookLikes.filter(uid => uid !== user.uid);
        } else {
          // If the user has not liked the book, like it
          userLikedBooks.push(id);
          bookLikes.push(user.uid);
        }
  
        // Update the user's liked books
        transaction.update(userRef, { likedBooks: userLikedBooks });
  
        // Update the book's likes
        transaction.update(bookRef, { likes: bookLikes });
      });
    } else {
      openModal(); // 로그인 페이지로 이동하는 코드
    }
  };
  
  return (
    <IconButton onClick={handleLike} color="secondary" aria-label="favorite" sx={{ position: 'relative', zIndex: 1 }}>
      {user && isLiked ? (  // Check if user is not null
        <SvgIcon
          sx={{ width: 36, height: 36, color: "#db4455" }}
          component={FavoriteIcon}
        />
      ) : (
        <SvgIcon
          sx={{ width: 36, height: 36, color: "#bbb" }}
          component={FavoriteBorderIcon}
        />
      )}
    </IconButton>
  );
};


export default LikeBook;

// LikeBook.js
// import React from 'react';
// import { useUserAuth } from "../AuthProvider";
// import { auth, firestore } from "../Firebase";
// import { IconButton, SvgIcon } from '@mui/material';
// import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';

// const LikeBook = ({ id, likes }) => {
//   const {user} = useUserAuth(auth);

//   const likeRef = doc(firestore, "books", id);

//   const handleLike = (event) => {
//     event.stopPropagation();  // Prevent event bubbling
//     if (user) {  // Check if user is not null
//       if (!likes) {
//         updateDoc(likeRef, {
//           likes: [user.uid],
//         }).catch((e) => {
//           console.log(e);
//         });
//       } else if (likes.includes(user.uid)) {
//         updateDoc(likeRef, {
//           likes: arrayRemove(user.uid),
//         }).catch((e) => {
//           console.log(e);
//         });
//       } else {
//         updateDoc(likeRef, {
//           likes: arrayUnion(user.uid),
//         }).catch((e) => {
//           console.log(e);
//         });
//       }
//     }
//   };
  
//   return (
//     <IconButton onClick={handleLike} color="secondary" aria-label="favorite">
//       {user && likes?.includes(user.uid) ? (  // Check if user is not null
//         <SvgIcon
//           sx={{ width: 36, height: 36, color: "#ee0000" }}
//           component={FavoriteIcon}
//         />
//       ) : (
//         <SvgIcon
//           sx={{ width: 36, height: 36, color: "#bbb" }}
//           component={FavoriteBorderIcon}
//         />
//       )}
//     </IconButton>
//   );
// };

// export default LikeBook;


